header {
  position: relative;
  display: inline-block;
  background: var(--bs-body-bg);
  backdrop-filter: blur(0);
  width: 100%;
  z-index: 999;
  transition: all 0.3s ease-out 0s;

  .toggle-rounded-btn {
    height: 30px;
    width: 30px;
    padding: 0;
    line-height: 30px;
    text-align: center;
    font-size: 20px;
  }

  .navbar-brand {
    margin: 0;
    display: inline-block;

    img {
      &.logo {
        height: 2.0625rem;
        max-height: 2.0625rem;
      }
    }

    svg {
      width: 10rem;
    }
  }

  .mobile-offcanvas {
    .navbar-brand {
      padding: 0;
    }
  }

  &.sticky {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(0.625em);
    -webkit-animation: header-slide-down 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: header-slide-down 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-fill-mode: initial;
    transition: all 0.3s ease-out 0s;
  }

  &.header-absolute,
  .header-absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, .3);
    backdrop-filter: blur(.625em);
  }

  .navbar-toggler-icon {
    background-image: none;
    position: relative;
    background: none;
    text-align: center;
    background: var(--bs-primary);
  }

  .search-box {

    .form-group {
      .input-group-text {
        padding: 12px 0 12px 16px;
      }

      .form-control {
        font-size: 14px;
        padding: 12px 16px;

        +.input-group-text {
          padding: 12px 16px 12px 16px;
        }
      }
    }
  }

  .navbar-nav {
    .search-box {
      &.dropdown-menu {
        padding: 0;
        background: transparent;
      }
    }
  }

  // header search
  &.header-search {
    .dropdown {
      .dropdown-toggle-item {
        display: inline-block;
        color: var(--bs-body-color);
        padding: 1.25rem 0;
        line-height: 40px;

        &:hover {
          color: var(--bs-white);
        }
      }

      .dropdown-menu {
        border: none;
        margin-top: -2px !important;
        line-height: inherit;
      }

      &.dropdown-search-wrapper {
        width: 100%;

        .search-box {
          width: 100%;
          padding: 0;
          background-color: transparent;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {

  // header search
  header {
    &.header-search {
      .search-box {
        display: block;
        position: static;
      }
    }
  }
}

/*====================
Slide Down Animation
=========================*/
@-webkit-keyframes header-slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes header-slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.iq-search-bar {
  &.dropdown-menu {
    border: none;
    min-width: 25em;
    left: auto;
    right: 0;
  }

  .search-input {
    height: 4em;
    padding: 0 1em;
    border: none;
    border-radius: 0;
    color: var(--bs-white);
  }

  .input-group-text {
    padding: 0;
    background-color: transparent;
  }

  .search-submit {
    background: var(--bs-gray-900);
    color: var(--bs-white);
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: none;
    height: 2.8em;
    width: 2.8em;
    position: absolute;
    top: 50%;
    left: auto;
    right: 0;
    border-left: .8px solid var(--bs-border-color-translucent);
    transform: translateY(-50%);
    z-index: 1;
  }

  &:has(.remove-search.d-none) .form-control{
    padding-right: 2.8em !important;
  }

  .remove-search ,
  .remove-fullsearch {
    color: var(--bs-primary);
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    z-index: 1;
    font-size: 12px;
    line-height: 0;
  }
}

.search-box {
  .form-group {
    animation: scale-out-hor-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  .iq-search-bar {
    .form-control {
      padding-left: 1.5em !important;
      padding-right: 4.8em !important;
      background: var(--bs-gray-900);
      z-index: 0;
    }
  }
}

.search-box a.show+ul li .form-group {
  animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.dropdown-search.dropdown-menu[data-bs-popper] {
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  min-width: 20rem;
}
.search-not-found {
  .form-control {
    &:focus {
      z-index: 1;
    }
  }
  .remove-fullsearch {
    color: var(--bs-primary);
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 65px;
    z-index: 1;
    font-size: 12px;
    line-height: 0;
  }
}

@include media-breakpoint-up(md) {
  header {
    .navbar-nav {
      .search-box {
        .dropdown-menu {
          display: inline-block;
          margin: 0;
          padding: 0;
          top: 50%;
          right: 0;
          background: transparent;
          transform: scaleX(0) translateY(-50%);
          transform-origin: 100% 100%;
          transition: all .3s ease-in-out;

          &.show {
            transform: scaleX(1) translateY(-50%);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  header {
    .navbar-nav {
      .search-box {
        .form-group {
          animation: none;
        }

        .dropdown-menu {
          display: inline-block;
          width: 100% !important;
          margin: 0 !important;
          padding: 0 !important;
          min-width: auto;
          position: static;
          background: transparent;
          transform: none;
          transition: all .3s ease-in-out;
        }
      }
    }
  }
}

@media(max-width: 319.98px) {
  header {
    .search-box {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  header {
    &.header-search {
      .subscribe-btn {
        padding: .8em .9em;
        font-size: 10px;
      }
    }
  }
}

.search-page {
  header {
    .right-panel {
      .search-box {
        display: none;
      }
    }
  }
}

@-webkit-keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1
  }
}

@keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1
  }
}

@-webkit-keyframes scale-out-hor-right {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1
  }

  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1
  }
}

@keyframes scale-out-hor-right {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1
  }

  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1
  }
}
