.season-card{
    background-color: var(--bs-gray-900);

    .season-image{
        position: relative;

        img{
            min-height: 100%;
            height: 163px;
            width: 250px;
        }
    }

    .season-meta-list{
        gap: 8px 38px;

        .season-meta-list-item{
            position: relative;

            &::after{
                content: "";
                background-color: var(--bs-body-color);
                height: 6px;
                width: 6px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: -22px;
                transform: translateY(-50%);
            }

            &:last-child{
                &::after{
                    content: none;
                }
            }
        }

    }

    .season-watch-btn{
        position: absolute;
        right: 8px;
        bottom: 8px;
        padding: 7px 16px;
        background-color: var(--bs-gray-800);
        color: var(--bs-white);
        font-size: 12px;
        font-weight: 500;
        border-radius: var(--bs-border-radius);
        border: none;
        outline: none;
        transition: all .3s ease-in;

        &:hover{
            background-color: var(--bs-primary);
        }
    }
}

@include media-breakpoint-down(sm){
    .season-card {
        .season-image {
            img {
                min-height: inherit;
                height: 163px;
                width: 100%;
            }
        }
    }
}