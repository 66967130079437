.iti--allow-dropdown .iti__flag-container, 
.iti--separate-dial-code .iti__flag-container {
    right: 0;
    left: auto;
}

.iti__country-list {
    text-align: inherit;
    padding: 0;
}

.iti--allow-dropdown {
    .input-style-box {
        padding-left: 0.75rem !important;
        padding-right: 75px;
        text-align: right;
    }
}

.input-group {
    .form-control{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--bs-border-radius) !important;
        border-bottom-right-radius: var(--bs-border-radius) !important;
        border-left: none;
        border-right: 1px solid transparent;

        &+ .input-group-text{
            border-top-left-radius: var(--bs-border-radius) !important;
            border-bottom-left-radius: var(--bs-border-radius) !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-left: 1px solid transparent;
            border-right: none;
        }

        &:focus {
            border-right: 1px solid var(--bs-primary);
            &+ .input-group-text {
                border-left: 1px solid var(--bs-primary);
            }
        }
    }
}

.form-check {
    .form-check-input {
        float: right;
        margin-left: 0;
        margin-right: 1.5rem;
    }
} 