[data-bs-theme="dark"] {
    $gray-900: #0D1619;
    --#{$prefix}gray-900: #{$gray-900};
    --#{$prefix}gray-900-rgb: #{to-rgb($gray-900)};
    $gray-800: #22292E;
    --#{$prefix}gray-800: #{$gray-800};
    --#{$prefix}gray-800-rgb: #{to-rgb($gray-800)};
    $gray-700: #050c21;
    --#{$prefix}gray-700: #{$gray-700};
    --#{$prefix}gray-700-rgb: #{to-rgb($gray-700)};
    $gray-600: #d7dbdf;
    --#{$prefix}gray-600: #{$gray-600};
    --#{$prefix}gray-600-rgb: #{to-rgb($gray-600)};
    $gray-500: #2f2f2f;
    --#{$prefix}gray-500: #{$gray-500};
    --#{$prefix}gray-500-rgb: #{to-rgb($gray-500)};
    $gray-400: #1c1e2f;
    --#{$prefix}gray-400: #{$gray-400};
    --#{$prefix}gray-400-rgb: #{to-rgb($gray-400)};
    $gray-300: #222738;
    --#{$prefix}gray-300: #{$gray-300};
    --#{$prefix}gray-300-rgb: #{to-rgb($gray-300)};
    $gray-200: #181818;
    --#{$prefix}gray-200: #{$gray-200};
    --#{$prefix}gray-200-rgb: #{to-rgb($gray-200)};
    $gray-100: #999797;
    --#{$prefix}gray-100: #{$gray-100};
    --#{$prefix}gray-100-rgb: #{to-rgb($gray-100)};
    // Shadow
    --#{$prefix}box-shadow: 0 0.063rem 1.875rem 0 rgba(#{to-rgb($black)}, 0.3);
    --#{$prefix}box-shadow-sm: 0 0.125rem 0.25rem rgba(#{to-rgb($black)}, 0.1);
    --#{$prefix}box-shadow-lg: 0 0.625rem 1.875rem rgba(#{to-rgb($black)}, 0.5);
    --#{$prefix}box-shadow-inset: inset 0 0.25rem 0.5rem rgba(#{to-rgb($black)}, 0.16);

}

@each $color, $value in $theme-colors {
    :root {
        @each $name, $hax-value in $value {
            --#{$variable-prefix}#{$color}: #{$value};
            --#{$variable-prefix}#{$color}-rgb: #{to-rgb($value)};
            --#{$variable-prefix}#{$color}-bg-subtle: #{tint-color($value, 80%)};
            --#{$variable-prefix}#{$color}-border-subtle: var(--#{$variable-prefix}#{$color}-bg-subtle);
            --#{$variable-prefix}#{$color}-hover-bg: #{shade-color($value, 20%)};
            --#{$variable-prefix}#{$color}-hover-border: #{shade-color($value, 30%)};
            --#{$variable-prefix}#{$color}-active-bg: #{shade-color($value, 20%)};
            --#{$variable-prefix}#{$color}-active-border: #{shade-color($value, 30%)};

            &[data-#{$variable-prefix}theme="dark"] {
                --#{$variable-prefix}#{$color}-text-emphasis: #{tint-color($value, 40%)};
                --#{$variable-prefix}#{$color}-bg-subtle: #{shade-color($value, 80%)};
            }
        }
    }
}