.genres-card {
  .genres-img{
    height: 215px;
    width: 100%;
    object-position: top;
  }

  &::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 63.57%, #000000 100%);
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .geners-title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
    z-index: 99;
    color: var(--bs-heading-color);
    font-weight: $font-weight-medium;
    line-height: 28px;
  }
}

@include media-breakpoint-down(lg){
  .genres-card { 
    .genres-img {
      height: auto;
      min-height: 120px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .genres-card {
    .geners-title {
      bottom: 16px;
    }
  }
}
