// Payment History start
.payment-history{
    tr:first-child th:first-child{
      border-top-left-radius: 6px;
    }
    tr:last-child td:first-child{
      border-bottom-left-radius: 6px;
    }
    tr:first-child th:last-child{
      border-top-right-radius: 6px;
    }
    tr:last-child td:last-child{
      border-bottom-right-radius: 6px;
    }
      .payment-info{
        background-color: var(--bs-gray-900);
        tr {
          border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);   
         &:last-child{
          border: none;
         }
        }
      }        
    }
// end
// Subscription plan payment table
.stripe-payment-form {
  background-color: var(--bs-gray-900);
  .payment-radio-btn{
    position: absolute;
    top: 22px;
    right: 24px;
  }
}

.payment-detail {
  padding: 30px;
  background-color: var(--bs-gray-900);

  th,
  tr,
  td {
    padding: 10px 0;
  }

}
// end