.slide-data-less {
    .slick-slider {
        .slick-list {
            .slick-track {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}

// card-style-slider
.card-style-slider {
    .slick-arrow {
        .slick-nav {
            i {
                display: inline-block;
                transform: scaleX(-1);
            }
        }
    }
    // default
    .slick-arrow {
        &.slick-arrow-prev {
            .slick-nav {
                left: auto;
                right: -2rem;
            }

            &::after {
                right: 0;
                left: auto;
                background: linear-gradient(-270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
            }
        }

        &.slick-arrow-next {
            .slick-nav {
                left: -2rem;
                right: auto;
            }

            &::after {
                left: 0;
                right: auto;
                background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
            }
        }

        &:not(.slick-disabled) {
            &.slick-arrow-prev {
                .slick-nav {
                    left: auto;
                    right: 1rem;
                }

                &::after {
                    width: 100px;
                }
            }

            &+.slick-list {
                &+.slick-arrow-next {
                    .slick-nav {
                        left: 1rem;
                        right: auto;
                    }

                    &::after {
                        width: 100px;
                    }
                }
            }
        }

    }

    // on hover
    &:hover {
        .slick-arrow {
            &.slick-arrow-prev {
                .slick-nav {
                    left: auto;
                    right: 1rem;
                }

                &::after {
                    width: 100px;
                }
            }

            &.slick-arrow-next {
                .slick-nav {
                    right: auto;
                    left: 1rem;
                }

                &::after {
                    width: 100px;
                }
            }
        }
    }
}

.slick-banner {
    .slick-arrow {
        i {
            transform: scaleX(-1);
            display: inline-block;
        }
    }

    .slick-arrow-prev {
        left: auto;
        right: 2rem;
    }

    .slick-arrow-next {
        left: 2rem;
        right: auto;
    }
}

@media(max-width: 1400px) {
    .slick-banner {
        .slick-arrow {
            &.slick-arrow-prev {
                right: 0;
            }

            &.slick-arrow-next {
                left: 0;
            }
        }
    }
}