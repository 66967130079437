.iq-top-ten-block {
  .block-image {
    position: relative;
    .overly-images {
      position: relative;
      z-index: 1;
      display: block;
      .top-ten-img {
        width: 100%;
        height: 330px;
        object-position: top;
        border-radius: var(--bs-border-radius);
        transition: all 0.6s ease 0s;
      }
    }
    .top-ten-numbers {
      position: absolute;
      bottom: -45px;
      left: 0;
      font-weight: 800;
      font-size: 7.5em;
      transition: all 0.4s ease-out;
      z-index: 2;
    }
    .product-premium {
      background: var(--bs-warning);
      border-radius: 100%;
      color: var(--bs-white);
      height: 1.75rem;
      left: auto;
      line-height: 1.9rem;
      position: absolute;
      right: 1rem;
      text-align: center;
      top: .875rem;
      width: 1.75rem;
      z-index: 100;
    }
  }
  &:hover {
    .block-image {
      .top-ten-numbers {
        bottom: -35px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .iq-top-ten-block {
    .block-image {
      .top-ten-numbers {
        font-size: 4.5rem !important;
      }
    }
  }
}
@include media-breakpoint-down(md){
  .iq-top-ten-block {
    .block-image {
      .overly-images {
        .top-ten-img {
          height: 260px;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm){
  .iq-top-ten-block {
    .block-image {
      .overly-images {
        .top-ten-img {
          height: 200px;
        }
      }
    }
  }
}
