.channel-card {

    .channel-img {
        min-width: 100%;
        width: 215px;
        height: 215px;
    }
}
@include media-breakpoint-down(md) {
    .channel-card {

        .channel-img {
            height: 180px;
        }
    }
}
@include media-breakpoint-down(sm) {
    .channel-card {

        .channel-img {
            height: 150px;
        }
    }
}