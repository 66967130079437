.passionate-people {
    background-color: var(--bs-gray-900);
    padding: 45px 32px 45px 30px;

    .designation {
        font-size: 14px;
    }

    .passionate-people-img {
        padding: 35px 0;
        border-radius: 50%;
    }
}