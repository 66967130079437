 // upgrade pan
 .upgrade-plan {
     margin-bottom: 80px;

     i {
         font-size: 48px;
     }

     .super-plan {
         font-size: 18px;
     }
 }

 // subscription plan
 .subscription-plan-wrapper {
     position: relative;
     padding: 40px;
     background-color: var(--bs-gray-900);
     border: 1px solid var(--bs-border-color);

     &.active {
         background-color: var(--bs-primary-bg-subtle);
         border: 1px solid var(--bs-primary);
     }

     .discount-offer {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        background: var(--bs-primary);
        border-radius: 0 var(--bs-border-radius);
        padding: 6px 12px;
        color: var(--bs-white);
        font-size: $font-size-sm;
    }

     .subscription-plan-offer {
         position: absolute;
         top: -1px;
         right: -1px;
         padding: 8px 12px;
         font-size: 14px;
         font-weight: $font-weight-medium;
         background: var(--bs-primary);
         color: var(--bs-heading-color);
         border-bottom-left-radius: 6px;
         border-top-right-radius: 6px;
     }

     .subscription-plan-header {
         .subscription-name {
             font-weight: $font-weight-medium;
             font-size: 18px;
         }

         .subscription-price {
             font-size: 2rem;
             font-weight: $font-weight-medium;
             color: var(--bs-heading-color);

             .subscription-price-desc {
                 font-weight: $font-weight-medium;
                 font-size: 16px;
                 color: var(--bs-body-color);
             }
         }
     }

     .subscription-details {
         margin: 44px 0px 60px 0px;

         ul {
             .list-desc {
                 margin-bottom: 22px;
                 font-weight: $font-weight-medium;

                 i {
                     margin-right: 4px;
                     vertical-align: middle;
                     font-size: 18px;
                 }

                 .active {
                     color: var(--bs-heading-color);
                 }

             }
         }
     }

     .subscription-btn {
         padding: 16px;
         width: 100%;
         font-size: 14px;
         font-weight: $font-weight-medium;
     }
 }

 @include media-breakpoint-down(lg) {
     .upgrade-plan {
         margin-bottom: 50px;
     }
 }

 @include media-breakpoint-down(sm) {
     .upgrade-plan {
         margin-bottom: 30px;
     }

 }